@font-face {
    font-family: "icon";
    src:
        url("./Icons/icon.ttf") format("truetype"),
        url("./Icons/icon.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    color: rgb(34, 34, 34);
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="time"],
select,
textarea,
button {
    font-family: "Inter", sans-serif;
}
